import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaGooglePlusG, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";
import footer_logo from "../../images/footer_logo.png";
import Contact from "./Contact";

const LandingPageFooter = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleModal = () => {
    setModalShow(true);
  };

  return (
    <Row className="footerText pb-1 px-md-5 gy-5 gx-0">
      <Col md={2}>
        <div style={{ marginTop: "-27px" }} className="mb-2">
          <div className="fs-2">
            <img src={footer_logo} alt="" width="160px" />
          </div>
        </div>
        <small style={{ marginLeft: "35px", fontSize: "11px" }}>
          Online Tutoring Platform
        </small>
        <div>
          <small style={{ marginLeft: "35px", fontSize: "11px" }}>
            Trade License # 176065
          </small>
        </div>
      </Col>
      <Col md={3} className="d-flex justify-content-around p-md-0">
        <div className="footerBorder">
          <Link to="/aboutus" className="text-decoration-none footerText">
            <p className="text-nowrap ms-2 ms-md-0">About Us</p>
          </Link>
          <Link to="/faq" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">FAQ</p>
          </Link>
          <p
            onClick={handleModal}
            className="mt-3 contactInfo text-nowrap ms-2 ms-md-0"
          >
            Contact
          </p>
        </div>
        <div className="footerTopRightBorder">
          <Link to="/allclasses" className="text-decoration-none footerText">
            <p className="text-nowrap ms-2 ms-md-0">Classes</p>
          </Link>
          <Link to="/allsubjects" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">Subjects</p>
          </Link>
          <Link to="/" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">Tutors</p>
          </Link>
        </div>
      </Col>
      <Col md={3} className="d-flex justify-content-around p-md-0">
        <div className="footerLeftBorder">
          <Link to="/" className="text-decoration-none footerText">
            <p className="text-nowrap ms-2 ms-md-0">Sign in</p>
          </Link>
          <Link to="/" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">Sign up</p>
          </Link>
        </div>
        <div className="footerRightBorder">
          <Link to="/" className="text-decoration-none footerText">
            <p className="text-nowrap ms-2 ms-md-0">Privacy Policy</p>
          </Link>
          <Link to="/" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">
              Terms and Conditions
            </p>
          </Link>
          <Link to="/" className="text-decoration-none footerText">
            <p className="mt-3 text-nowrap ms-2 ms-md-0">Refund Policy</p>
          </Link>
        </div>
      </Col>
      <Col md={4}>
        <div className="ms-md-3">
          <div className="d-flex">
            <a
              href="https://www.facebook.com/tutoryclub"
              target="blank"
              className="text-decoration-none text-white btn w-50 linkBg"
            >
              <FaFacebookF className="fs-3 me-1" /> Facebook
            </a>

            <a
              href="https://www.youtube.com/channel/UCiT5J-yh5yJrAqpwGi__1hQ/featured"
              target="blank"
              className="text-decoration-none text-white btn w-50 ms-2 youtube"
            >
              <FiYoutube className="fs-3 me-1" /> YouTube
            </a>
          </div>
          <div className="d-flex mt-1">
            <a
              href="https://www.linkedin.com/company/83951068"
              target="blank"
              className="text-decoration-none text-white btn w-50 bg-info"
            >
              <FaLinkedinIn className="fs-3 me-1" /> Linkedin
            </a>
            <a
              href="https://g.page/r/CV3wqIqC7CFfEAE"
              target="blank"
              className="text-decoration-none text-white btn w-50 ms-2 orangeBg"
            >
              <FaGooglePlusG className="fs-3 me-1" /> Google
            </a>
          </div>
        </div>
      </Col>
      <div className="mt-3 mb-2">
        © 2021 - {new Date().getFullYear()} Tutory.club
      </div>
      <Contact show={modalShow} onHide={() => setModalShow(false)} />
    </Row>
  );
};

export default LandingPageFooter;
