import React from 'react';
import "./NotFound.css"
import failled from "../../images/404.gif";

const NotFound = () => {
    return (
        <div className='nofFound_main'>
            <img className='nofFound_image fluid' src={failled} alt="404" />
        </div>
    );
};

export default NotFound;