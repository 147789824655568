import React from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Navbar className="px-0 px-md-4 py-md-4 headers" bg="light" expand="lg">
      <Container fluid className="header_width">
        <Link to="/" className="pe-5 pe-md-0 py-3 py-md-0">
          <Navbar.Brand>
            <img className="logo" src={logo} alt="Tutory Club" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="px-3">
          <Nav className="me-auto">
            <Link
              to="/StudentFeature"
              className="menu-text pt-0 pt-md-3 menuItem text-decoration-none me-md-3"
            >
              STUDENTS
            </Link>
            <Link
              to="/tutorFeature"
              className="menu-text pt-1 pt-md-3 text-decoration-none me-md-3"
            >
              TUTORS
            </Link>
            <Link
              to="/aboutus"
              className="menu-text pt-1 pt-md-3 text-decoration-none me-md-3"
            >
              ABOUT US
            </Link>
            <Link
              to="/contact"
              className="menu-text pt-1 pt-md-3 text-decoration-none me-md-3"
            >
              CONTACT
            </Link>
          </Nav>
          <Nav className="ms-auto pt-1 pt-md-0">
            <button className="btn btn-outline-info me-md-2 menu-btn">
              SIGN IN
            </button>
            <button className="btn btn-outline-info mt-2 mt-md-0 menu-btn">
              SIGN UP
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
