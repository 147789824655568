import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import slide_1 from "../../images/slider_1.svg";
import slide_2 from "../../images/slider_2.svg";
import slide_3 from "../../images/slider_3.svg";
import slide_4 from "../../images/slider_4.svg";

const TutorsFeaturesSlider = () => {
  return (
    <div className="featureParent">
      <Carousel>
        <Carousel.Item>
          <Row className="featureExchange gx-0">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">TIME IS MONEY!</h3>
                <h5 className="slide-description ">
                  Elimination of travel time for tutors. Rush-hour commutes can
                  be truly horrible! You also save on transport costs.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_1}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  GEOGRAPHICAL CONSTRAINTS
                </h3>
                <h5 className="slide-description">
                  Elimination of geographical constraints – tutors can teach the
                  students without being limited by the area in which they live
                  in.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_2}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  FLEXIBILITY AND CONVENIENCE
                </h3>
                <h5 className="slide-description">
                  Lessons can be done from any global location and at more
                  flexible times (e.g. early morning or night). All you need is
                  a device such as a computer, tablet or mobile and an internet
                  connection.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_3}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  GOOD FOR STUDY & CAREER DEVELOPMENT
                </h3>
                <h5 className="slide-description">
                  Online tutoring improves your academic study as well as career
                  development by increasing your confidence and communication.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_4}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default TutorsFeaturesSlider;
