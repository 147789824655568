import React from "react";
import { FcBusinessman } from "react-icons/fc";
import { RiTeamLine, RiCustomerService2Line } from "react-icons/ri";

const StudentsFeaturesCommit = () => {
  return (
    <div className="d-md-flex justify-content-around container">
      <div className="px-5 commit-width mb-3">
        <FcBusinessman className="display-2 p-2 border border-3 rounded-circle text-info" />
        <h5 className="mt-2 fw-bold">Sourcing Best Tutors</h5>
        <p className="commit-description">
          Sourcing Quality Tutors from good institutions with excellent track
          records.
        </p>
      </div>
      <div className="px-5 commit-width mb-3">
        <RiCustomerService2Line className="display-2 p-2 border border-3 rounded-circle text-success" />
        <h5 className="mt-2 fw-bold">Uninterrupted Service</h5>
        <p className="commit-description">
          Ensuring uptime to provide uninterrupted service round the clock.
        </p>
      </div>
      <div className="px-5 commit-width mb-3">
        <RiTeamLine className="display-2 p-2 border border-3 rounded-circle text-warning" />
        <h5 className="mt-2 fw-bold">Supportive Team</h5>
        <p className="commit-description">
          We are always on hand to support our tutors and resolve issues
          quickly.
        </p>
      </div>
    </div>
  );
};

export default StudentsFeaturesCommit;
