import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./LandingPage.css";
import slider_1 from "../../images/slider_1.svg";
import slider_2 from "../../images/slider_2.svg";
import slider_3 from "../../images/slider_3.svg";
import slider_4 from "../../images/slider_4.svg";
import { FcBinoculars, FcCalendar, FcCurrencyExchange } from "react-icons/fc";
import LandingPageSubject from "./LandingPageSubject";
import LandingPageClass from "./LandingPageClass";
import LandingPageTutors from "./LandingPageTutors";
import LandingProgressBar from "./LandingProgressBar";
import LandingPageTutorClub from "./LandingPageTutorClub";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [image, setImage] = useState();
  const common = slider_1;
  const sliderImage = [slider_1, slider_2, slider_3, slider_4];

  useEffect(() => {
    let count = 0;
    setInterval(function () {
      let index = count++;
      if (index === 3) {
        count = 0;
      }
      index = index + 1;
      setImage(sliderImage[index === 4 ? (index = 0) : index]);
    }, 4000);
    return () => {
      setImage();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Row className="mt-4 g-0 mx-3 mx-md-5">
      {/* Banner Part */}
      <Col md={12} className="p-0 bg-slider">
        <Row className="hero mx-auto">
          {/* hero part */}
          <Col md={7} className="bg-top-part">
            <h2 className="text-white mt-5 ms-md-4 landingTitle">
              LIVE Class from Best Tutors in the Country
            </h2>
            <h4 className="mt-5 ms-md-4">
              Real Live Learning in Small Batches for
            </h4>
            <span className="fw-bold text-white fs-3 ms-md-4">Class 1-12</span>

            {/* This part for searching */}
            <div className="ms-md-4 mt-3">
              <form className="d-md-flex">
                <div className="form-group d-md-flex mb-2 align-items-center">
                  <select
                    className="form-select mx-md-2 box-color"
                    name="curriculum"
                  >
                    <option value="">Select Curriculum</option>
                    <option value="">National Curriculum (Bangla Ver)</option>
                    <option value="">National Curriculum (English Ver)</option>
                    <option value="">English Medium (Cambridge)</option>
                    <option value="">English Medium (Edexcel)</option>
                    <option value="">Vocational</option>
                  </select>
                </div>
                <div className="form-group d-md-flex mb-2 align-items-center">
                  <select
                    className="form-select mx-md-2 box-color"
                    name="subject"
                  >
                    <option value="">Select Subject</option>
                    <option value="">Bangla</option>
                    <option value="">English</option>
                    <option value="">Math</option>
                    <option value="">Computer</option>
                    <option value="">Physics</option>
                    <option value="">Others</option>
                  </select>
                </div>
                <div className="form-group d-md-flex mb-2 align-items-center">
                  <select
                    className="form-select mx-md-2 box-color"
                    name="classLevel"
                  >
                    <option value="">Select Class</option>
                    <option value="">Class-1</option>
                    <option value="">Class-2</option>
                    <option value="">Class-3</option>
                    <option value="">Class-4</option>
                    <option value="">Class-5</option>
                    <option value="">Class-6</option>
                    <option value="">Class-7</option>
                    <option value="">Class-8</option>
                    <option value="">Class-9</option>
                    <option value="">Class-10</option>
                    <option value="">Class-11</option>
                    <option value="">Class-12</option>
                  </select>
                </div>

                <div className="form-group d-md-flex mb-2">
                  <Button className="bg-color fw-bold">FIND</Button>
                </div>
              </form>
              {/* Happy student */}
              <div className="d-md-flex align-items-center text-center mt-4">
                <div className="fs-4 happyText">
                  <strong>35000+</strong>
                  <p className="text-white">
                    <strong>Happy Students</strong>
                  </p>
                </div>
                <div className="ms-md-5 fs-4 happyText mt-3 mt-md-0">
                  <strong>3500+</strong>
                  <p className="text-white">
                    <strong>Total Class</strong>
                  </p>
                </div>
                <div className="ms-md-5 fs-4 happyText mt-3 mt-md-0">
                  <strong>4.7/5</strong>
                  <p className="text-white">
                    <strong>Tutors Rating(Avg.)</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="ms-md-4 py-4 happyBtn">
              <Button className="bg-colors">Book a Demo Live Classes</Button>
            </div>
          </Col>
          <Col md={1}></Col>
          <Col
            md={4}
            className="top hero-image"
            style={{
              backgroundImage: `url(${image !== undefined ? image : common})`,
              backgroundRepeat: "no-repeat",
              WebkitTransition: "background-image 1s ease-in-out",
              backgroundPosition: "center",
            }}
          ></Col>
        </Row>
      </Col>

      {/* diffrent typy of class */}
      <Col md={12} className="p-0 my-5">
        <Row className="g-2">
          <Col md={4} className="p-0 pe-md-2">
            <div className="d-flex bg-event align-items-center py-3 px-4 shadow classType rounded">
              <FcCalendar className="display-4" />
              <div className="d-flex justify-content-between w-100">
                <div className="px-4">
                  <strong className="fs-3">TODAY'S</strong>
                  <br />
                  <strong className="fs-5">Classes</strong>
                </div>
                <strong className="bg-white rounded-circle p-2 fs-2 border text-dark">
                  10
                </strong>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-0 pe-md-2">
            <div className="d-flex bg-event align-items-center py-3 px-4 shadow classType rounded">
              <FcCurrencyExchange className="display-4" />
              <div className="d-flex justify-content-between w-100">
                <div className="px-4">
                  <strong className="fs-3">FREE</strong>
                  <br />
                  <strong className="fs-5">Classes</strong>
                </div>
                <strong className="bg-white rounded-circle p-2 fs-2 border text-dark">
                  25
                </strong>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-0 pe-md-2">
            <div className="d-flex bg-event align-items-center py-3 px-4 shadow classType rounded">
              <FcBinoculars className="display-4" />
              <div className="d-flex justify-content-between w-100">
                <div className="px-4">
                  <strong className="fs-3">UPCOMING</strong>
                  <br />
                  <strong className="fs-5">Classes</strong>
                </div>
                <strong className="bg-white rounded-circle p-2 fs-2 border text-dark">
                  32
                </strong>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {/* find your subject experts */}
      <Col md={12} className="p-0 ladnding-margin-top text-center ">
        <Container className="pb-5">
          <h1 className="mb-4 text-secondary fw-bold pt-4 title-text">
            Find Your Subject Experts
          </h1>
          <LandingPageSubject />
          <Link to="/allsubjects">
            <Button className="mt-5 bg-colors px-5">Explore Courses</Button>
          </Link>
        </Container>
      </Col>

      {/* find your class experts */}
      <Col md={12} className="p-0 ladnding-margin-top text-center ">
        <Container className="pb-5">
          <h1 className="mb-4 text-secondary fw-bold pt-4 title-text">
            Find Your Class Experts
          </h1>
          <LandingPageClass />
          <Link to="/allclasses">
            <Button className="mt-5 bg-colors px-5">Explore Classes</Button>
          </Link>
        </Container>
      </Col>
      {/* Meet Our Amazing Tutors */}
      <Col md={12} className="p-0 mt-5 text-center position-static">
        <div className="pb-5">
          <h1 className="fw-bold pt-4 greenText title-text">
            Meet Our Amazing Tutors
          </h1>
          <p>
            You can do a TEST Class before enroll your course with any
            tutors.This is a BEST initiatives for you to make you happy!
          </p>
          <LandingPageTutors />
        </div>
      </Col>

      {/* The Effect on Reality */}
      <Col md={12} className="p-0 text-center pb-5 ondisplay">
        <div className="pb-5">
          <h1 className="fw-bold pt-4 greenText title-text">
            The Effect on Reality
          </h1>
          <p>
            We are conducting regular survey on Students and Parents about their
            improvements...
          </p>
          <LandingProgressBar />
        </div>
      </Col>

      {/* 4 reason to learn on Tutory Club */}
      <Col md={12} className="p-0 mt-2 text-center pb-5">
        <div className="pb-5">
          <h2 className="orangeText fw-bold pt-4 title-text">
            4 Reason to Learn on Tutory Club
          </h2>
          <LandingPageTutorClub />
        </div>
      </Col>
    </Row>
  );
};

export default LandingPage;
