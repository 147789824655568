import Header from "./components/Header/Header";
import LandingPage from "./components/LandingPage/LandingPage";
import LandingPageFooter from "./components/LandingPage/LandingPageFooter";
import { Routes, Route } from "react-router-dom";
import AllClasses from "./components/AllClasses/AllClasses";
import AllSubjects from "./components/AllClasses/AllSubjects";
import StudentFeature from "./components/Features/StudentFeature";
import TutorFeature from "./components/Features/TutorFeature";
import About from "./components/LandingPage/About";
import FAQ from "./components/LandingPage/FAQ";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MenuContact from "./components/LandingPage/MenuContact";
import NotFound from "./components/NotFound/NotFound"

function App() {
  return (
    <div className="mainDiv">
      <ScrollToTop>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Header />
                <LandingPage />
                <div md={12} className="px-0 pt-5 border bg-dark">
                  <LandingPageFooter />
                </div>
              </>
            }
          />
          <Route
            exact
            path="/allclasses"
            element={
              <>
                <Header />
                <AllClasses />
              </>
            }
          />
          <Route
            exact
            path="/allsubjects"
            element={
              <>
                <Header />
                <AllSubjects />
              </>
            }
          />
          <Route
            exact
            path="/tutorFeature"
            element={
              <>
                <Header />
                <TutorFeature />
              </>
            }
          />
          <Route
            exact
            path="/studentFeature"
            element={
              <>
                <Header />
                <StudentFeature />
              </>
            }
          />
          <Route
            exact
            path="/aboutus"
            element={
              <>
                <Header />
                <About />
              </>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <>
                <Header />
                <MenuContact />
                <div md={12} className="px-0 pt-5 border bg-dark">
                  <LandingPageFooter />
                </div>
              </>
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <>
                <Header />
                <FAQ />
              </>
            }
          />
          <Route
            exact
            path="/*"
            element={
              <>
               <NotFound/>
              </>
            }
          />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
