import React from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { RiTeamLine } from "react-icons/ri";
import { MdOutlineStarRate } from "react-icons/md";

const TutorsFeaturesCommit = () => {
  return (
    <div className="d-md-flex justify-content-around container">
      <div className="px-5 commit-width mb-3">
        <GiTakeMyMoney className="display-2 p-2 border border-3 rounded-circle text-info" />
        <h5 className="mt-2 fw-bold">Fast Payments</h5>
        <p className="commit-description">
          Get paid weekly/monthly and on time. We take payments seriously.
        </p>
      </div>
      <div className="px-5 commit-width mb-3">
        <RiTeamLine className="display-2 p-2 border border-3 rounded-circle text-success" />
        <h5 className="mt-2 fw-bold">Supportive Team</h5>
        <p className="commit-description">
          We are always on hand to support our tutors and resolve issues
          quickly.
        </p>
      </div>
      <div className="px-5 commit-width mb-3">
        <MdOutlineStarRate className="display-2 p-2 border border-3 rounded-circle text-warning" />
        <h5 className="mt-2 fw-bold">Flexible Rates</h5>
        <p className="commit-description">
          We are offering affordable service charge for platform use.
        </p>
      </div>
    </div>
  );
};

export default TutorsFeaturesCommit;
