import React, { useRef } from "react";
import "./MenuContact.css";
import { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import { FcTwoSmartphones } from "react-icons/fc";
import contact from "../../images/contact.jpg"

const MenuContact = () => {
  const form = useRef();
  const [mailDetails, setMailDetails] = useState({
    mailName: "",
    mailPhone: "",
    mailAddress: "",
    mailSubject: "",
    mailText: "",
  });

  const handleBlur = (e) => {
    const newMail = { ...mailDetails };
    newMail[e.target.name] = e.target.value;
    setMailDetails(newMail);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_yz1b0dj",
        "template_1g4gazg",
        form.current,
        "K-qpvUFnuQFW7tLLb"
      )
      .then(
        () => {
          swal({
            title: "Good job!",
            text: "Your Email Send Successfully!",
            icon: "success",
            button: "OK",
          });
        },
        (error) => {
          swal({
            title: "Oh No!",
            text: error.text,
            icon: "warning",
            button: "OK",
          });
        }
      );

    document.getElementById("contactForm").reset();
  };

  return (
    <div>
      <div
        className="contactmenu"
        style={{
          backgroundImage: `url('${contact}')`,
        }}
      >
        <h1 className="display-5 fw-bold contactTitle ms-5">Contact Us</h1>
      </div>
      <Row className="gx-0 mx-2 mx-md-5 contact-main mb-5">
        <Col xm-={12} lg={3}>
          <div className="mt-2 mt-md-5">
            <div className="card info-width text-center shadow p-3">
              <h5>
                <CiLocationOn className="fs-2" />
              </h5>
              <div className="text-secondary">
                Holding # GA-36 (Level-4) <br /> Shahjadpur, Gulshan, Dhaka-1212
              </div>
            </div>
          </div>
          <div className="mt-2 mt-md-5">
            <div className="card info-width text-center shadow p-3">
              <h5>
                <MdOutlineMailOutline className="fs-2" />
              </h5>
              <div className="text-secondary">info@tutory.club</div>
            </div>
          </div>
          <div className="mt-2 mt-md-5">
            <div className="card info-width text-center shadow p-3">
              <h5>
                <FcTwoSmartphones className="fs-2" />
              </h5>
              <div className="text-secondary">+880 1316686977</div>
            </div>
          </div>
        </Col>
        <Col xm={12} md={5}>
          <Card className="mt-5 p-md-4 bg-menuContact shadow">
            <h4 className="text-dark text-center mt-3 mt-md-0">
              Ask Your Question
            </h4>
            <hr className="text-dark mb-3" />
            <form
              id="contactForm"
              onSubmit={sendEmail}
              ref={form}
              className="p-2"
            >
              <div className="d-flex">
                <Form.Group className="mb-1 w-50">
                  <Form.Control
                    required
                    type="text"
                    name="mailName"
                    placeholder="Name"
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group className="mb-1 w-50 ms-2">
                  <Form.Control
                    required
                    type="number"
                    name="mailPhone"
                    placeholder="Phone"
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-1">
                <Form.Control
                  required
                  type="email"
                  name="mailAddress"
                  placeholder="Email"
                  onBlur={handleBlur}
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Control
                  required
                  type="text"
                  name="mailSubject"
                  placeholder="Subject"
                  onBlur={handleBlur}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Control
                  required
                  as="textarea"
                  name="mailText"
                  rows={4}
                  placeholder="Write here..."
                  onBlur={handleBlur}
                />
              </Form.Group>
              <div className="text-start">
                <button
                  className="linkBg form_button btn btn-primary mt-2 rounded fw-bold"
                  type="submit"
                >
                  SEND
                </button>
              </div>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MenuContact;
