import React from "react";
import "./StudentFeature.css";
import { Col, Row } from "react-bootstrap";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import StudentsFeaturesLearn from "../StudentsFeaturesView/StudentsFeaturesLearn";
import StudentsFeaturesSlider from "../StudentsFeaturesView/StudentsFeaturesSlider";
import StudentsFeaturesWork from "../StudentsFeaturesView/StudentsFeaturesWork";
import StudentsFeaturesCommit from "../StudentsFeaturesView/StudentsFeaturesCommit";

const StudentFeature = () => {
  return (
    <div>
      <div className="p-0 mt-4 text-center pb-5">
        <div style={{ backgroundColor: "#F6F6F6" }} className="py-2">
          <StudentsFeaturesSlider />
        </div>
      </div>
      <Row className="div_width g-0 px-2 px-md-0">
        <Col md={12} className="p-0 mt-2 text-center pb-5">
          <div className="pb-5">
            <h2 className="greenText fw-bold pt-4 f-title">
              Reason to learn on Tutory Club
            </h2>
            <StudentsFeaturesLearn />
          </div>
        </Col>
        <Col
          md={12}
          className="p-0 mt-2 text-center pb-5 d-flex justify-content-center"
        >
          <div>
            <h2 className="fw-bold py-4 f-title greenText">How To Start</h2>
            <StudentsFeaturesWork />
          </div>
        </Col>
        <Col md={12} className="p-0 mt-2 text-center pb-5">
          <div>
            <h2 className="fw-bold py-5 f-title greenText">
              We commit to Student
            </h2>
            <StudentsFeaturesCommit />
          </div>
        </Col>
      </Row>
      <div className="px-0 pt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default StudentFeature;
