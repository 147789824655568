import React from "react";
import { Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { SiWolframmathematica } from "react-icons/si";
import { RiEnglishInput, RiComputerLine } from "react-icons/ri";
import { GiMaterialsScience, GiDoctorFace } from "react-icons/gi";
import { MdScience, MdAccountBalance } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

const LandingPageSubject = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <Carousel
      className="my-1"
      showDots={isMobile ? true : false}
      responsive={responsive}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-danger">
          <SiWolframmathematica />
        </Card.Body>
        <strong className="fs-4">MATH</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-primary">
          <RiEnglishInput />
        </Card.Body>
        <strong className="fs-4">ENGLISH</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-success">
          <GiMaterialsScience />
        </Card.Body>
        <strong className="fs-4">PHYSICS</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-warning">
          <MdScience />
        </Card.Body>
        <strong className="fs-4">CHEMISTRY</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-info">
          <GiDoctorFace />
        </Card.Body>
        <strong className="fs-4">BIOLOGY</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-danger">
          <RiComputerLine />
        </Card.Body>
        <strong className="fs-4">ICT</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-muted">
          <MdAccountBalance />
        </Card.Body>
        <strong className="fs-4">ACCOUNTING</strong>
      </Card>
    </Carousel>
  );
};

export default LandingPageSubject;
