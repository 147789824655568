import React from "react";
import "./AllClasses.css";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSchool, FaUniversalAccess } from "react-icons/fa";
import { FcShop } from "react-icons/fc";
import sub from "../../images/sub.jpg"
import {
  GiPschentDoubleCrown,
  GiSchoolBag,
  GiStumpRegrowth,
} from "react-icons/gi";
import { IoIosSchool } from "react-icons/io";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

const AllClasses = () => {
  return (
    <div className="mt-3 mx-3">
      <Row className="gy-2 gy-md-4 div_width gx-0">
        <Col
          md={12}
          className="class-banner align-items-center justify-content-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url("${sub}")`,
          }}
        >
          <h3 className="text-white banner-text text-center">All Classes</h3>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/Class-1"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6 " style={{ color: "#BA4A00" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-1</p>
                  <p className="mt-2">
                    <strong className="total-course">6</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/Class-2"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#ABEBC6" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-2</p>
                  <p className="mt-2">
                    <strong className="total-course">16</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/Class-3"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#17A589" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-3</p>
                  <p className="mt-2">
                    <strong className="total-course">2</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/Class-4"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#884EA0" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-4</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/Class-5"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#9A7D0A" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold subject-Title">Class-5</p>
                  <p className="mt-2">
                    <strong className="total-course">14</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/Class-6"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#3498DB" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-6</p>
                  <p className="mt-2">
                    <strong className="total-course">5</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/Class-7"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#D4AC0D" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-7</p>
                  <p className="mt-2">
                    <strong className="total-course">5</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/Class-8"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#D4AC0D" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-8</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/Class-9"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#D4AC0D" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-9</p>
                  <p className="mt-2">
                    <strong className="total-course">4</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/Class-10"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "red" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-10</p>
                  <p className="mt-2">
                    <strong className="total-course">25</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/Class-11"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#4D1212" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-11</p>
                  <p className="mt-2">
                    <strong className="total-course">20</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/Class-12"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcShop className="display-6" style={{ color: "#7BD126" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Class-12</p>
                  <p className="mt-2">
                    <strong className="total-course">13</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/PSC"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiPschentDoubleCrown
                    className="display-6"
                    style={{ color: "#D12679" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">PSC</p>
                  <p className="mt-2">
                    <strong className="total-course">9</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/JSC"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FaSchool
                    className="display-6"
                    style={{ color: "#2686D1" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">JSC</p>
                  <p className="mt-2">
                    <strong className="total-course">6</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/SSC"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <IoIosSchool
                    className="display-6"
                    style={{ color: "#E2E53B" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">SSC</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchClasses/HSC"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiSchoolBag
                    className="display-6"
                    style={{ color: "#E5A73B" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">HSC</p>
                  <p className="mt-2">
                    <strong className="total-course">4</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchClasses/O Level"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FaUniversalAccess
                    className="display-6"
                    style={{ color: "green" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">O Level</p>
                  <p className="mt-2">
                    <strong className="total-course">5</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchClasses/A Level"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiStumpRegrowth
                    className="display-6"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">A Level</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
      <div className="px-0 pt-5 mt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default AllClasses;
