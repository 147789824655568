import React from "react";

const FeaturesDetails = () => {
  return (
    <div className="d-md-flex">
      <div className="border-design">
        <p className="step-text px-2 fs-5" style={{ color: "red" }}>
          Step 1
        </p>
        <div className="circle1 marginStart">
          <h3 className="text-white circle-text">Sign-up</h3>
        </div>
        <p className="circle-bottom-text1">Free Registration</p>
      </div>

      <div className="border-design">
        <p className="step-text px-2 fs-5" style={{ color: "orange" }}>
          Step 2
        </p>
        <div className="circle2 marginStart">
          <h3 className="circle-text text-white">Create Profile</h3>
        </div>
        <small className="circle-bottom-text2">
          Input your data to build a professional Profile
        </small>
      </div>

      <div className="border-design">
        <p
          className="step-text px-2 fs-5"
          style={{ color: "rgb(91, 91, 202)" }}
        >
          Step 3
        </p>
        <div className="circle3 marginStart">
          <h3 className="circle-text text-white">Create Course</h3>
        </div>
        <small className="circle-bottom-text3">
          Create a course that you love to teach
        </small>
      </div>

      <div className="border-design">
        <p
          className="step-text px-2 fs-5"
          style={{ color: "rgb(104, 194, 104)" }}
        >
          Step 4
        </p>
        <div className="circle4 marginStart">
          <h3 className="circle-text text-white">Join Class</h3>
        </div>
        <small className="circle-bottom-text4">Join the Live Class</small>
      </div>

      <div className="border-design-end">
        <p
          className="step-text px-2 fs-5"
          style={{ color: "rgba(139, 104, 194, 0.87)" }}
        >
          Step 5
        </p>
        <div className="circle5 marginStart">
          <h3 className="circle-text text-white">Get Paid</h3>
        </div>
        <small className="circle-bottom-text5">
          Payment added to the wallet when a student enters the class.
        </small>
      </div>
    </div>
  );
};

export default FeaturesDetails;
