import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import slide_1 from "../../images/slider_1.svg";
import slide_2 from "../../images/slider_2.svg";
import slide_3 from "../../images/slider_3.svg";
import slide_4 from "../../images/slider_4.svg";

const StudentsFeaturesSlider = () => {
  return (
    <div className="featureStuParent">
      <Carousel>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  ONLINE TUTORING IS INTERACTIVE AND INNOVATIV
                </h3>
                <h5 className="slide-description">
                  One significant benefit of online tutoring is its potential
                  for student engagement. Children today are products of a
                  digital age, and many thrive in a virtual environment. We have
                  a proven ability to hold students’ attention and help them
                  grow cognitively through our powerful learning platform.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_1}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  PLAN TUTORING SESSIONS AROUND YOUR SCHEDULE
                </h3>
                <h5 className="slide-description">
                  With online tutoring, you can set your student’s tutoring
                  sessions when it works for you. With availability in the
                  morning, evenings as well as weekends.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_2}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  TECHNOLOGY ADAPTATION AND LEARNING
                </h3>
                <h5 className="slide-description">
                  Students nowadays are used to an online world and all the
                  technology associated with online lessons and are therefore
                  able to learn well this way.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_3}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="featureExchange">
            <Col md={1}></Col>
            <Col md={5} className="d-flex align-items-center">
              <div className="text-start px-3">
                <h3 className="fw-bold slide-title">
                  GOOD FOR STUDY & CAREER DEVELOPMENT
                </h3>
                <h5 className="slide-description">
                  Online tutoring improves your academic study as well as career
                  development by increasing your confidence and communication.
                </h5>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <img
                className="d-block f-slider-image"
                src={slide_4}
                alt="Third slide"
              />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default StudentsFeaturesSlider;
