import React from "react";
import "./TutorsFeatures.css";
import { Col, Row } from "react-bootstrap";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import TutorsFeaturesSlider from "../TutorsFeaturesView/TutorsFeaturesSlider";
import TutorsFeaturesView from "../TutorsFeaturesView/TutorsFeaturesView";
import FeaturesDetails from "../TutorsFeaturesView/FeaturesDetails";
import TutorsFeaturesCommit from "../TutorsFeaturesView/TutorsFeaturesCommit";
import TutorFeaturesExpect from "../TutorsFeaturesView/TutorFeaturesExpect";

const TutorFeature = () => {
  return (
    <div>
      <div className="p-0 mt-4 text-center pb-5">
        <div style={{ backgroundColor: "#F6F6F6" }} className="py-2">
          <TutorsFeaturesSlider />
        </div>
      </div>
      <Row className="div_width g-0">
        <Col md={12} className="p-0 mt-2 text-center pb-5">
          <div className="pb-5 px-2">
            <h2 className="greenText fw-bold pt-4 f-title">
              Reason to Teach on Tutory Club
            </h2>
            <TutorsFeaturesView />
          </div>
        </Col>
        <Col
          md={12}
          className="p-0 mt-2 text-center pb-5 d-flex justify-content-center"
        >
          <div>
            <h2 className="fw-bold py-4 f-title greenText">How To Start</h2>
            <FeaturesDetails />
          </div>
        </Col>
        <Col md={12} className="p-0 mt-2 text-center pb-5">
          <div>
            <h2 className="fw-bold py-5 f-title greenText">
              We Commit To Tutor
            </h2>
            <TutorsFeaturesCommit />
          </div>
        </Col>
      </Row>
      <div
        className="p-0 mt-2 mb-5 text-center"
        style={{ backgroundColor: "#F6F6F6" }}
      >
        <div>
          <h2 className="fw-bold py-5 f-title greenText">
            We expect from Tutor
          </h2>
          <TutorFeaturesExpect />
        </div>
      </div>
      <div className="px-0 pt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default TutorFeature;
