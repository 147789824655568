import React from "react";

const StudentsFeaturesWork = () => {
  return (
    <div className="d-md-flex">
      <div className="student-circle-design">
        <div className="student-circle1">
          <h3 className="text-white student-circle-text">Sign-up</h3>
        </div>
        <p className="px-2 pt-4 student-circle-buttom-text">
          Free Registration
        </p>
      </div>
      <div className="student-circle-design student-circle-margin">
        <div className="student-circle2">
          <h3 className="text-white student-circle-text">Create Profile</h3>
        </div>
        <p className="px-3 pt-4 student-circle-buttom-text">
          Input your required data to complete your profile
        </p>
      </div>
      <div className="student-circle-design student-circle-margin">
        <div className="student-circle3">
          <h3 className="text-white student-circle-text">Enrolled Course</h3>
        </div>
        <p className="px-2 pt-4 student-circle-buttom-text">
          Find your desired course and enrolled
        </p>
      </div>
      <div className="student-circle-design student-circle-margin">
        <div className="student-circle4">
          <h3 className="text-white student-circle-text">Add Fund</h3>
        </div>
        <p className="px-2 pt-4 student-circle-buttom-text">
          Add Fund through Card and Mobile Banking
        </p>
      </div>
      <div className="student-circle-design student-circle-margin">
        <div className="student-circle5">
          <h3 className="text-white student-circle-text">Join Class</h3>
        </div>
        <p className="px-2 pt-4">Join the Live Class</p>
      </div>
    </div>
  );
};

export default StudentsFeaturesWork;
