import React from "react";
import { GiThreeFriends, GiRelationshipBounds } from "react-icons/gi";
import { FcCheckmark } from "react-icons/fc";

const TutorFeaturesExpect = () => {
  return (
    <div className="d-md-flex justify-content-around mb-5 pb-5 mx-2">
      <div className="px-5 expect-border mb-3 p-3 m-md-2 shadow bg-white">
        <FcCheckmark className="display-1 p-2 expect-icon rounded-circle shadow" />
        <h5 className="mt-4 fw-bold">Qualified and Experienced</h5>
        <ul className="text-start mt-3">
          <li>Previous experience tutoring and or teaching.</li>
          <li>A track record of academic success.</li>
          <li>Well knowledge about modern technology.</li>
        </ul>
      </div>
      <div className="px-5 expect-border mb-3 p-3 m-md-2 shadow bg-white">
        <GiThreeFriends className="display-1 p-2 expect-icon rounded-circle shadow" />
        <h5 className="mt-4 fw-bold">Friendly</h5>
        <ul className="text-start mt-3">
          <li>Passionate about their subject.</li>
          <li>Well prepared, interesting and can engage with their students</li>
          <li>Delivers fun and productive lessons.</li>
        </ul>
      </div>
      <div className="px-5 expect-border mb-3 p-3 m-md-2 shadow bg-white">
        <GiRelationshipBounds className="display-1 p-2 expect-icon rounded-circle shadow" />
        <h5 className="mt-4 fw-bold">Committed</h5>
        <ul className="text-start mt-3">
          <li>A minimum commitment of one academic year.</li>
          <li>Dedication to their students.</li>
          <li>
            Will communicate progress to both the student, their parents and
            Tutory Club.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TutorFeaturesExpect;
