import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MdAccountBalance,
  MdOutlineHistoryEdu,
  MdOutlineScience,
} from "react-icons/md";
import { FaArtstation, FaBusinessTime } from "react-icons/fa";
import { FcBiotech, FcStatistics } from "react-icons/fc";
import { AiOutlineApartment } from "react-icons/ai";
import { BsBookHalf, BsBank } from "react-icons/bs";
import {
  MdLanguage,
  MdOutlineManageAccounts,
  MdOutlineControlCamera,
  MdSocialDistance,
} from "react-icons/md";
import {
  SiWorldhealthorganization,
  SiReasonstudios,
  SiSqlite,
  SiWolframmathematica,
  SiFluentd,
} from "react-icons/si";
import {
  GiChemicalDrop,
  GiMaterialsScience,
  GiDesk,
  GiRelicBlade,
  GiMadScientist,
  GiClawHammer,
  GiPsychicWaves,
  GiThermometerScale,
  GiLoveHowl,
} from "react-icons/gi";
import { RiEnglishInput } from "react-icons/ri";
import { CgFormatHeading } from "react-icons/cg";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import sub from "../../images/sub.jpg"

const AllSubjects = () => {
  return (
    <div className="mt-3 mx-3">
      <Row className="gy-2 gy-md-4 div_width gx-0">
        <Col
          md={12}
          className="class-banner align-items-center justify-content-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url("${sub}")`,
          }}
        >
          <h3 className="text-white banner-text">All Subjects</h3>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Accounting"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdAccountBalance
                    className="display-6 "
                    style={{ color: "#BA4A00" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Accounting</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Art"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FaArtstation
                    className="display-6"
                    style={{ color: "#ABEBC6" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Art</p>
                  <p className="mt-2">
                    <strong className="total-course">4</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Art & Design"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <AiOutlineApartment
                    className="display-6"
                    style={{ color: "#17A589" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Art & Design</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Bangla"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <BsBookHalf
                    className="display-6"
                    style={{ color: "#884EA0" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Bangla</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Bangladesh and Global Studies"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <SiWorldhealthorganization
                    className="display-6"
                    style={{ color: "#9A7D0A" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold subject-Title">
                    Bangladesh and Global Studies
                  </p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Bangladesh Studies"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <SiReasonstudios
                    className="display-6"
                    style={{ color: "#3498DB" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Bangladesh Studies</p>
                  <p className="mt-2">
                    <strong className="total-course">2</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Business Entrepreneurship"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FaBusinessTime
                    className="display-6"
                    style={{ color: "#D4AC0D" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Business Entrepreneurship</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Biology"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcBiotech
                    className="display-6"
                    style={{ color: "#D4AC0D" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Biology</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Chemistry"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiChemicalDrop
                    className="display-6"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Chemistry</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Combined Science"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiMaterialsScience
                    className="display-6"
                    style={{ color: "#4D1212" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Combined Science</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Computer Science"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiDesk className="display-6" style={{ color: "#7BD126" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Computer Science</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/English"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <RiEnglishInput
                    className="display-6"
                    style={{ color: "#D12679" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">English</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/English Language"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdLanguage
                    className="display-6"
                    style={{ color: "#2686D1" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">English Language</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/English Literature"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <SiSqlite
                    className="display-6"
                    style={{ color: "#E2E53B" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">English Literature</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Environment Management"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdOutlineManageAccounts
                    className="display-6"
                    style={{ color: "#E5A73B" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Environment Management</p>
                  <p className="mt-2">
                    <strong className="total-course">9</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Ethics and Religions"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiRelicBlade
                    className="display-6"
                    style={{ color: "#F3672E" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Ethics and Religions</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Economics"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdOutlineControlCamera
                    className="display-6"
                    style={{ color: "#796E4D" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Economics</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Finance and Banking"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <BsBank className="display-6" style={{ color: "#4096CF" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Finance and Banking</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/General Science"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiMadScientist
                    className="display-6"
                    style={{ color: "#7C40CF" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">General Science</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Higher Math"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <CgFormatHeading
                    className="display-6"
                    style={{ color: "#CF40B7" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Higher Math</p>
                  <p className="mt-2">
                    <strong className="total-course">3</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/History"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdOutlineHistoryEdu
                    className="display-6"
                    style={{ color: "#9866DD" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">History</p>
                  <p className="mt-2">
                    <strong className="total-course">6</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Law"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiClawHammer
                    className="display-6"
                    style={{ color: "#16C2E1" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Law</p>
                  <p className="mt-2">
                    <strong className="total-course">10</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Math"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <SiWolframmathematica
                    className="display-6"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Math</p>
                  <p className="mt-2">
                    <strong className="total-course">7</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Music and Drama"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <SiFluentd className="display-6" style={{ color: "gold" }} />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Music and Drama</p>
                  <p className="mt-2">
                    <strong className="total-course">9</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Psychology"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiPsychicWaves
                    className="display-6"
                    style={{ color: "#16E1B3" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Psychology</p>
                  <p className="mt-2">
                    <strong className="total-course">12</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Physics"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiThermometerScale
                    className="display-6"
                    style={{ color: "#E17516" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Physics</p>
                  <p className="mt-2">
                    <strong className="total-course">9</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Religion"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <GiLoveHowl
                    className="display-6"
                    style={{ color: "#E1CE16" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Religion</p>
                  <p className="mt-2">
                    <strong className="total-course">2</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 pe-md-2">
          <Link
            to="/searchSubject/Science"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdOutlineScience
                    className="display-6"
                    style={{ color: "#E15116" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Science</p>
                  <p className="mt-2">
                    <strong className="total-course">8</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 px-md-2">
          <Link
            to="/searchSubject/Statistics"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <FcStatistics
                    className="display-6"
                    style={{ color: "#E15116" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Statistics</p>
                  <p className="mt-2">
                    <strong className="total-course">4</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col md={4} className="p-0 ps-md-2">
          <Link
            to="/searchSubject/Sociology"
            target="blank"
            className="text-decoration-none"
          >
            <Card className="shadow border px-3 py-1 rounded subject-card">
              <div className="d-flex align-items-center">
                <div>
                  <MdSocialDistance
                    className="display-6"
                    style={{ color: "green" }}
                  />
                </div>
                <div className="ms-4">
                  <p className="fw-bold">Sociology</p>
                  <p className="mt-2">
                    <strong className="total-course">5</strong>{" "}
                    <small>Courses</small>
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
      <div className="px-0 pt-5 mt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default AllSubjects;
