import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { FcAlarmClock } from "react-icons/fc";
import { BsPeopleFill } from "react-icons/bs";
import { RiCalendarLine } from "react-icons/ri";
import { RatingStar } from "rating-star";
import tutor1 from "../../images/tutor1.jpg"
import tutor2 from "../../images/tutor2.png"
import tutor3 from "../../images/tutor3.jpg"

const LandingPageTutors = () => {
  return (
    <Row className="mt-5 mx-auto gy-3 overflow-hidden">
      <Col md={4} className="p-0">
        <Card className="subjectBorder p-4 text-start landingCard shadow mx-auto expertAnimation">
          <div className="d-flex">
            <div className="">
              <img
                className="meet_image rounded-circle mb-2 mt-3"
                src={tutor1}
                alt="tutors profile"
              />
              <br />
              <div className="text-center ftop">
                <p className="landing_Tutor_heart rounded-circle ms-md-4">
                  <FaHeart className="fs-4 py-1" />
                </p>
                <p className="text-nowrap pt-1">
                  <span className="ftext">100</span>{" "}
                  <span className="follower">followers</span>
                </p>
              </div>
            </div>
            <div className="ms-3">
              <div className="text-decoration-none orangeText">
                <h3 className="fw-bold tutorName">Md. Rakibul Islam</h3>
              </div>
              <h3 className="fw-bold uvText">Post Graduate</h3>

              <h3 className=" uvText">University Of Dhaka</h3>
              <p className="mt-2">
                <span className="orangeText fw-bold">5+</span>{" "}
                <span className="experience">years teaching experience</span>
              </p>
              <hr />
              <div className="d-flex ps-3 ps-md-0">
                <h3 className="tutor_rating fw-bold">4.8</h3>
                <div className="tutors-rating-border mx-1"></div>
                <div className="tutor_top_secondary text-nowrap">
                  <RatingStar id="123" size={20} rating={4.8} />
                  <p>
                    <small>{12} reviews</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="border border-2" />
          <div className="lessonFelx">
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <FcAlarmClock className="fs-1" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Lessons</span> <br />
                <strong className="fw-bold jointext orangeText">13</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <BsPeopleFill className="fs-1 text-success" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Students</span> <br />
                <strong className="fw-bold jointext orangeText">50</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <RiCalendarLine className="fs-1 text-info" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Join here</span> <br />
                <small className="fw-bold jointext orangeText text-nowrap">
                  2022-05-03
                </small>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="mt-1 speaksText">
              <strong>Speaks: </strong>
              <span>Bangla, English</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Local Language: </strong>
              <span>Chittagong</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Current Location:</strong> Dhaka
            </p>
          </div>
          <div className="d-md-flex mt-4 justify-content-between">
            <Button className="landing-button mb-2 text-white fw-bold buttonText">
              Book A Demo Class
            </Button>
            <Button className="bg-color mb-2 text-white fw-bold buttonText">
              See All Courses
            </Button>
          </div>
        </Card>
      </Col>
      <Col md={4} className="p-0">
        <Card className="subjectBorder p-4 text-start landingCard shadow mx-auto expertAnimation">
          <div className="d-flex">
            <div className="">
              <img
                className="meet_image rounded-circle mb-2 mt-3"
                src={tutor2}
                alt="tutors profile"
              />
              <br />
              <div className="text-center ftop">
                <p className="landing_Tutor_heart rounded-circle ms-md-4">
                  <FaHeart className="fs-4 py-1" />
                </p>
                <p className="text-nowrap pt-1">
                  <span className="ftext">80</span>{" "}
                  <span className="follower">followers</span>
                </p>
              </div>
            </div>
            <div className="ms-3">
              <div className="text-decoration-none orangeText">
                <h3 className="fw-bold tutorName">Farjana Akter</h3>
              </div>
              <h3 className="fw-bold uvText">Post Graduate</h3>

              <h3 className=" uvText">BUET</h3>
              <p className="mt-2">
                <span className="orangeText fw-bold">3+</span>{" "}
                <span className="experience">years teaching experience</span>
              </p>
              <hr />
              <div className="d-flex ps-3 ps-md-0">
                <h3 className="tutor_rating fw-bold">4.9</h3>
                <div className="tutors-rating-border mx-1"></div>
                <div className="tutor_top_secondary text-nowrap">
                  <RatingStar id="1234" size={20} rating={4.9} />
                  <p>
                    <small>{10} reviews</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="border border-2" />
          <div className="lessonFelx">
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <FcAlarmClock className="fs-1" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Lessons</span> <br />
                <strong className="fw-bold jointext orangeText">5</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <BsPeopleFill className="fs-1 text-success" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Students</span> <br />
                <strong className="fw-bold jointext orangeText">35</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <RiCalendarLine className="fs-1 text-info" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Join here</span> <br />
                <small className="fw-bold jointext orangeText text-nowrap">
                  2022-07-21
                </small>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="mt-1 speaksText">
              <strong>Speaks: </strong>
              <span>Bangla, English</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Local Language: </strong>
              <span>Barisal</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Current Location:</strong> Dhaka
            </p>
          </div>
          <div className="d-md-flex mt-4 justify-content-between">
            <Button className="landing-button mb-2 text-white fw-bold buttonText">
              Book A Demo Class
            </Button>
            <Button className="bg-color mb-2 text-white fw-bold buttonText">
              See All Courses
            </Button>
          </div>
        </Card>
      </Col>
      <Col md={4} className="p-0">
        <Card className="subjectBorder p-4 text-start landingCard shadow mx-auto expertAnimation">
          <div className="d-flex">
            <div className="">
              <img
                className="meet_image rounded-circle mb-2 mt-3"
                src={tutor3}
                alt="tutors profile"
              />
              <br />
              <div className="text-center ftop">
                <p className="landing_Tutor_heart rounded-circle ms-md-4">
                  <FaHeart className="fs-4 py-1" />
                </p>
                <p className="text-nowrap pt-1">
                  <span className="ftext">60</span>{" "}
                  <span className="follower">followers</span>
                </p>
              </div>
            </div>
            <div className="ms-3">
              <div className="text-decoration-none orangeText">
                <h3 className="fw-bold tutorName">Abdul-AL-Mamun</h3>
              </div>
              <h3 className="fw-bold uvText">Post Graduate</h3>

              <h3 className=" uvText">University of Chittagong</h3>
              <p className="mt-2">
                <span className="orangeText fw-bold">2+</span>{" "}
                <span className="experience">years teaching experience</span>
              </p>
              <hr />
              <div className="d-flex ps-3 ps-md-0">
                <h3 className="tutor_rating fw-bold">4.5</h3>
                <div className="tutors-rating-border mx-1"></div>
                <div className="tutor_top_secondary text-nowrap">
                  <RatingStar id="12345" size={20} rating={4.6} />
                  <p>
                    <small>{13} reviews</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="border border-2" />
          <div className="lessonFelx">
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <FcAlarmClock className="fs-1" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Lessons</span> <br />
                <strong className="fw-bold jointext orangeText">7</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <BsPeopleFill className="fs-1 text-success" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Students</span> <br />
                <strong className="fw-bold jointext orangeText">20</strong>
              </div>
            </div>
            <div className="d-flex box-color p-2 rounded me-1 mb-1 lessonsRight">
              <div>
                <RiCalendarLine className="fs-1 text-info" />
              </div>
              <div className="ms-3 lessonsLeft">
                <span>Join here</span> <br />
                <small className="fw-bold jointext orangeText text-nowrap">
                  2022-06-06
                </small>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="mt-1 speaksText">
              <strong>Speaks: </strong>
              <span>Bangla, English</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Local Language: </strong>
              <span>Chittagong</span>
            </p>
            <p className="mt-1 speaksText">
              <strong>Current Location:</strong> Chittagong
            </p>
          </div>
          <div className="d-md-flex mt-4 justify-content-between">
            <Button className="landing-button mb-2 text-white fw-bold buttonText">
              Book A Demo Class
            </Button>
            <Button className="bg-color mb-2 text-white fw-bold buttonText">
              See All Courses
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default LandingPageTutors;
