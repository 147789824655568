import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
// Animation
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";

const LandingProgressBar = () => {
  return (
    <Row className="mt-4">
      <Col md={4}>
        <div style={{ width: 250, height: 200 }} className="mx-auto">
          <VisibilitySensor>
            {({ isVisible }) => {
              const percentage = isVisible ? 90 : 0;
              return (
                <CircularProgressbarWithChildren
                  value={percentage}
                  styles={buildStyles({
                    textColor: "red",
                    pathColor: "#FF7D48",
                    trailColor: "#f8a585",
                  })}
                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong className="fs-3 orangeText">90%</strong>
                    <br />
                    <p className="mx-3">
                      Students have shown improvement in School performance
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              );
            }}
          </VisibilitySensor>
        </div>
      </Col>
      <Col md={4} className="margin-progress">
        <div style={{ width: 250, height: 200 }} className="mx-auto">
          <VisibilitySensor>
            {({ isVisible }) => {
              const percentage = isVisible ? 75 : 0;
              return (
                <CircularProgressbarWithChildren
                  value={percentage}
                  styles={buildStyles({
                    textColor: "red",
                    pathColor: "#1F78B4",
                    trailColor: "#6cbcf1",
                  })}
                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong className="fs-3 blueText">75%</strong>
                    <br />
                    <p className="mx-3 px-2">
                      Student have started devoting more time to self-study
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              );
            }}
          </VisibilitySensor>
        </div>
      </Col>
      <Col md={4} className="margin-progress">
        <div style={{ width: 250, height: 200 }} className="mx-auto">
          <VisibilitySensor>
            {({ isVisible }) => {
              const percentage = isVisible ? 85 : 0;
              return (
                <CircularProgressbarWithChildren
                  value={percentage}
                  styles={buildStyles({
                    textColor: "red",
                    pathColor: "#008593",
                    trailColor: "#0fb7ca",
                  })}
                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong className="fs-3 greenText">85%</strong>
                    <br />
                    <p className="mx-3">
                      Parents are really happy with the teaching style
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              );
            }}
          </VisibilitySensor>
        </div>
      </Col>
    </Row>
  );
};

export default LandingProgressBar;
