import React from "react";
import "./About.css";
import { Col, Row } from "react-bootstrap";
import { FaStudiovinari } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { GiMoneyStack } from "react-icons/gi";
import about from "../../images/about.svg";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

const About = () => {
  return (
    <div className="mt-5">
      <h1
        className="text-center fw-bold orangeText"
        style={{ fontFamily: "cursive" }}
      >
        Our Story
      </h1>
      <Row className="about-display align-items-center g-0">
        <Col md={1}></Col>
        <Col md={5}>
          <h5 style={{ textAlign: "justify" }} className="px-3">
            The story of TutoryClub begins with its founders’ desire to help
            students grow in confidence and achieve their goals. TutoryClub was
            founded in 2019 and has rapidly become one of Bangladeshi’s most
            successful and prestigious tutoring companies.
            <br />
            <br />
            TutoryClub's biggest priority is to work the very best tutors and
            make sure that students aren’t just taught but are also inspired.
            Our rapid growth was, and continues to be, based on the attention to
            our students’ needs that only a personal service such as ours can
            deliver.
          </h5>
        </Col>
        <Col md={5}>
          <img className="about-image" src={about} alt="about page img" />
        </Col>
        <Col md={1}></Col>
      </Row>

      <div className="bg-about-commit text-center my-5">
        <h2 className="fw-bold py-5 f-title greenText">Why Use TutoryClub?</h2>
        <div className="d-md-flex justify-content-around container pb-5">
          <div className="px-5 commit-width mb-3">
            <FaStudiovinari className="display-2 p-2 border border-3 rounded-circle text-info" />
            <h5 className="mt-2 fw-bold">Fast Matching</h5>
            <p className="about-commit-description">
              Our tailor-made online tutoring services will help you to find the
              best tutor for your desired subject within a few minutes! Yes,
              it’s really amazing.
            </p>
          </div>
          <div className="px-5 commit-width mb-3">
            <FcManager className="display-2 p-2 border border-3 rounded-circle text-success" />
            <h5 className="mt-2 fw-bold">Experienced Tutors</h5>
            <p className="about-commit-description">
              TutoryClub has an in-depth recruitment process ensuring we offer
              the best tutors from the good institution in the country.
            </p>
          </div>
          <div className="px-5 commit-width mb-3">
            <GiMoneyStack className="display-2 p-2 border border-3 rounded-circle text-success" />
            <h5 className="mt-2 fw-bold">Competitive Prices</h5>
            <p className="about-commit-description">
              We focus on results and student outcomes. You get what you pay for
              with TutoryCLub.
            </p>
          </div>
        </div>
      </div>
      <div className="px-0 pt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default About;
