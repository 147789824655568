import React from "react";
import "../Features/TutorsFeatures.css";
import {
  GiTimeBomb,
  GiSatelliteCommunication,
  GiFreedomDove,
  GiTakeMyMoney,
} from "react-icons/gi";
import { SiGnuprivacyguard } from "react-icons/si";
import { BsBarChartLine } from "react-icons/bs";
import { FaChalkboardTeacher, FaLaptop } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

const TutorsFeaturesView = () => {
  return (
    <Row className="mt-4 gy-4 gx-0">
      <Col md={6} className="d-flex reason-justify-content free-text">
        <div className="f-border">
          <SiGnuprivacyguard className="display-3 feature-icon-color1" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Free Registration
          </p>
          <small>There is no cost for Sign-up</small>
        </div>
      </Col>
      <Col md={6} className="d-flex club-padding">
        <div className="f-border">
          <GiTimeBomb className="display-3 feature-icon-color2" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Flexible Time
          </p>
          <small>Make a Class Schedule at your time</small>
        </div>
      </Col>
      <Col md={6} className="d-flex reason-justify-content time-text">
        <div className="f-border">
          <BsBarChartLine className="display-3 feature-icon-color3" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Optimized your valued time
          </p>
          <small>Truly Online thus no traveling, no time waste</small>
        </div>
      </Col>
      <Col md={6} className="d-flex club-padding">
        <div className="f-border">
          <GiTakeMyMoney className="display-3 feature-icon-color4" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Weekly or Monthly Payment
          </p>
          <small>Convenient Payment directly to Bank/Mobile</small>
        </div>
      </Col>

      <Col md={6} className="d-flex reason-justify-content mobile-text">
        <div className="f-border">
          <FaLaptop className="display-3 feature-icon-color5" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Accessibility and Mobility
          </p>
          <small>Take Class from Anywhere, Anytime</small>
        </div>
      </Col>
      <Col md={6} className="d-flex club-padding">
        <div className="f-border">
          <GiSatelliteCommunication className="display-3 feature-icon-color6" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Communication Fluency
          </p>
          <small>Increase your speaking abilities and confident</small>
        </div>
      </Col>
      <Col md={6} className="d-flex reason-justify-content indepen-text">
        <div className="f-border">
          <GiFreedomDove className="display-3 feature-icon-color7" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Independence
          </p>
          <small>Freedom to run its own way</small>
        </div>
      </Col>
      <Col md={6} className="d-flex club-padding">
        <div className="f-border">
          <FaChalkboardTeacher className="display-3 feature-icon-color8" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Smart Teaching
          </p>
          <small>Whiteboard, Instant Sharing, Homework, Test and more</small>
        </div>
      </Col>
    </Row>
  );
};

export default TutorsFeaturesView;
