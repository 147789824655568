import React from "react";
import {
  FcBusinesswoman,
  FcReadingEbook,
  FcCalendar,
  FcRules,
} from "react-icons/fc";
import { Col, Row } from "react-bootstrap";

const StudentsFeaturesLearn = () => {
  return (
    <Row className="mt-4 gy-4 gx-0">
      <Col md={6} className="d-flex justify-content-end tutors-text">
        <div className="f-border">
          <FcBusinesswoman className="display-3 feature-icon-color1" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Outstanding Tutors
          </p>
          <small>Select your tutors around the country</small>
        </div>
      </Col>
      <Col md={6} className="d-flex">
        <div className="f-border">
          <FcReadingEbook className="display-3 feature-icon-color2" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Customised Courses
          </p>
          <small>Based on your specific learning needs</small>
        </div>
      </Col>
      <Col md={6} className="d-flex justify-content-end lesson-text">
        <div className="f-border">
          <FcRules className="display-3 feature-icon-color3" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Pay per Lesson
          </p>
          <small>No need to prepay a lot of money to start</small>
        </div>
      </Col>
      <Col md={6} className="d-flex">
        <div className="f-border">
          <FcCalendar className="display-3 feature-icon-color4" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3 text-nowrap f-title-text">
            Flexible Schedule
          </p>
          <small>Book classes based on your own schedule </small>
        </div>
      </Col>
    </Row>
  );
};

export default StudentsFeaturesLearn;
