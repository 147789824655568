import React from "react";
import { GiTeacher, GiMoneyStack, GiBookshelf } from "react-icons/gi";
import { FcOvertime } from "react-icons/fc";
import { Col, Row } from "react-bootstrap";

const LandingPageTutorClub = () => {
  return (
    <Row className="mt-4 gy-5 gx-0">
      <Col md={6} className="d-flex text-direction pe-5">
        <div className="clubBorder">
          <GiTeacher className="display-1 text-info" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3">Outstanding Tutors</p>
          <small>Select your tutors around the country</small>
        </div>
      </Col>

      <Col md={6} className="d-flex">
        <div className="clubBorder">
          <GiBookshelf className="display-1 text-danger" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3">Customised Courses</p>
          <small>Based on your specific learning needs</small>
        </div>
      </Col>
      <Col md={6} className="d-flex text-direction">
        <div className="clubBorder">
          <GiMoneyStack className="display-1 text-success" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3">Pay per Lesson</p>
          <small>No need to prepay a lot of money to start</small>
        </div>
      </Col>
      <Col md={6} className="d-flex">
        <div className="clubBorder">
          <FcOvertime className="display-1 text-info" />
        </div>
        <div className="text-start ms-3">
          <p className="greenText fw-bold mb-3">Flexible Schedule</p>
          <small>Book classes based on your own schedule</small>
        </div>
      </Col>
    </Row>
  );
};

export default LandingPageTutorClub;
