import React, { useRef } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import emailjs from "emailjs-com";
import swal from "sweetalert";

const Contact = (props) => {
  const form = useRef();
  const [mailDetails, setMailDetails] = useState({
    mailName: "",
    mailPhone: "",
    mailAddress: "",
    mailSubject: "",
    mailText: "",
  });

  const handleBlur = (e) => {
    const newMail = { ...mailDetails };
    newMail[e.target.name] = e.target.value;
    setMailDetails(newMail);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_yz1b0dj",
        "template_1g4gazg",
        form.current,
        "K-qpvUFnuQFW7tLLb"
      )
      .then(
        () => {
          swal({
            title: "Good job!",
            text: "Your Email Send Successfully!",
            icon: "success",
            button: "OK",
          });
        },
        (error) => {
          swal({
            title: "Oh No!",
            text: error.text,
            icon: "warning",
            button: "OK",
          });
        }
      );

    document.getElementById("contactForm").reset();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-modal" closeButton>
        <Modal.Title id="contained-modal-title-vcenter fw-bold">
          New Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-modal">
        <form id="contactForm" onSubmit={sendEmail} ref={form} className="p-2">
          <div className="d-flex">
            <Form.Group className="mb-1 w-50">
              <Form.Control
                required
                type="text"
                name="mailName"
                placeholder="Name"
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group className="mb-1 w-50 ms-2">
              <Form.Control
                required
                type="number"
                name="mailPhone"
                placeholder="Phone"
                onBlur={handleBlur}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-1">
            <Form.Control
              required
              type="email"
              name="mailAddress"
              placeholder="Email"
              onBlur={handleBlur}
            />
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Control
              required
              type="text"
              name="mailSubject"
              placeholder="Subject"
              onBlur={handleBlur}
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Control
              required
              as="textarea"
              name="mailText"
              rows={4}
              placeholder="Write here..."
              onBlur={handleBlur}
            />
          </Form.Group>
          <div className="text-start">
            <button
              className="linkBg form_button btn btn-primary mt-2 rounded fw-bold"
              type="submit"
            >
              SEND
            </button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer className="bg-modal d-flex justify-content-between">
        <div>
          <strong>Office Location:</strong>
          <p className="mt-2">
            Holding # GA-36 (Level-4), Shahjadpur, Gulshan, Dhaka-1212,
            Bangladesh.
          </p>
          <p className="mt-1">Email: info@tutory.club, Phone: +8801316686977</p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Contact;
