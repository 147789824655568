import React, { useState } from "react";
import "./FAQ.css";
import { Col, Row } from "react-bootstrap";
import LandingPageFooter from "./LandingPageFooter";
import sub from "../../images/sub.jpg"

const FAQ = () => {
  const [show, setShow] = useState();

  const handleShow = (id) => {
    if (show === id) {
      setShow("");
    } else {
      setShow(id);
    }
  };

  return (
    <div>
      <Row className="div_width my-3 faq-main g-0 px-3">
        <Col
          md={12}
          className="top-banner align-items-center justify-content-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url("${sub}")`,
          }}
        >
          <h3 className="text-white banner-text">FAQ</h3>
        </Col>
        <Col md={3}></Col>
        <Col md={6} className="my-4">
          <h3 className="my-4 text-center">
            For Students and Parents Aspects!
          </h3>
          <div
            className="accordion accordion-flush shadow"
            id="accordionFlushExample"
          >
            <div className="accordion-item" onClick={() => handleShow(1)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Do I need to install any special software for tutoring or
                  learning?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 1 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border">
                  No, the system is web-based.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(2)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What equipment do I need for online classes?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 2 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You will require the following:
                  <br />
                  Laptop/Computer/Mobile, Internet Connection, Web Camera,
                  Headphone
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(3)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How can I find a tutor?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 3 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Use the Tutors page to view a list of tutors who have upcoming
                  and running courses. You can be filtered using many different
                  criteria, such as curriculum, subject, class, batch type, fees
                  range, and many more.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(4)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How can I contact a tutor?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 4 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You do not require to contact the tutor, you just enrolled in
                  your desired course and join the class as per schedule.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(5)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How much I am charged for class?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 5 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You do not require to make an upfront big amount, as per class
                  rate the amount is deducted from your wallet. You just ensure
                  the minimum class amount as per the enrolled course is
                  available in your wallet.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(6)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How can I add funds to my wallet?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 6 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You can add funds to your wallet by using any debit/credit
                  card and mobile financing service (MFS).
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(7)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How often should my child have class?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 7 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  It is clearly mentioned the class duration and days at course
                  information.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(8)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What results can I expect?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 8 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Results depend on a combination of many different factors but
                  essentially the more work a student puts in, the more they
                  will get out. TutoryClub has had professionally maintained
                  their regular homework, Assignment, Tests, etc thus students
                  are getting good grades in their academic exams.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(9)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Why should I have online classes/lessons?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 9 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Online classes/lessons are more flexible than in-person
                  lessons and there is no time wasted due to traveling. It also
                  means students can continue lessons when away on holiday.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(10)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What if the subject I want to learn isn’t listed?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 10 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You can inform us through the contact form. We will find the
                  tutors for your desired subjects and announce the course for
                  enrollment.
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col md={6} className="my-4">
          <h3 className="my-4 text-center">For Tutor Aspects!</h3>
          <div
            className="accordion accordion-flush shadow"
            id="accordionFlushExample"
          >
            <div className="accordion-item" onClick={() => handleShow(11)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Who is able to be a tutor at Tutory.club?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 11 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border">
                  Many types of professionals are doing teaching with
                  Tutory.club
                  <br />
                  • Teachers and retired teachers
                  <br /> • College and graduate students
                  <br />
                  • Math professionals/experts
                  <br /> • Science professionals/experts
                  <br /> • Computer science experts
                  <br /> • Art experts
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(12)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What are the technology requirements?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 12 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Laptop/Computer/Mobile, Internet Connection, Web Camera,
                  Headphone etc.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(13)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Do I need to install any special software for tutoring or
                  learning?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 13 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  No, the system is web-based.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(14)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Do I have to tutor for certain hours?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 14 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Tutory.club allows you to create a flexible schedule that fits
                  your needs. Our service is available 24 hours a day, 7 days a
                  week, which means you can create a new schedule on a
                  week-to-week basis. There are three ways to tutor:
                  <br />
                  • Create a course as per your available days and time schedule
                  each week
                  <br />
                  • Log on the portal as per the schedule and join the class
                  <br />• Assign homework to students through the system, check
                  the submitted homework, and do feedback if correction required
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(15)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Where does tutoring take place?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 15 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Our convenient online classroom lets you tutor from your home,
                  office, hall or from any computer with a stable, high-speed
                  internet connection. No travel expenses are necessary!
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(16)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How much are tutors paid?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 16 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  You are paid a per-class rate that you set at the time of
                  course creation. When students are entering the class, the
                  amount is added to your wallet in real-time after deducing the
                  commission of Tutory.club. At the weekend or month-end, the
                  full amount is transferred to your bank account or mobile
                  banking account. You do not require to spend a single minute
                  on that processing!
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(17)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How much commission of TutoryClub?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 17 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  Currently, we are taking a 20% commission/service charge for
                  managing the platform and marketing purposes.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(18)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How can I become a tutor?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 18 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  If you want to become a tutor, please go to the Sign-up page
                  and build your profile first thereafter create your courses
                  and start tutoring. For more details visit Tutor’s benefits
                  page.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-2" onClick={() => handleShow(19)}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What are an online tutor’s responsibilities?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${
                  show === 19 ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border faq-text">
                  • Connecting online with students of all ages to provide
                  homework help and tutoring in the subjects for which you have
                  demonstrated expertise.
                  <br />
                  • Guiding students to understand the topics they bring to each
                  tutoring session.
                  <br />
                  • Encouraging students to engage actively with their topics to
                  build deeper understanding.
                  <br />
                  • Helping build student confidence in their ability to learn
                  and to understand challenging concepts.
                  <br />
                  • Using state-of-the-art online tools such as an interactive
                  whiteboard, video, voice and chat communication, and
                  application sharing.
                  <br />
                  • Classifying each session according to the scope and sequence
                  of the topics discussed.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3}></Col>
      </Row>
      <div className="px-0 pt-5 border bg-dark">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default FAQ;
