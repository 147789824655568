import React from "react";
import Carousel from "react-multi-carousel";
import { Card } from "react-bootstrap";
import {
  GiPschentDoubleCrown,
  GiSchoolBag,
  GiStumpRegrowth,
} from "react-icons/gi";
import { IoIosSchool } from "react-icons/io";
import { FaRunning, FaSchool, FaUniversalAccess } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

const LandingPageClass = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <Carousel
      className="my-1"
      showDots={isMobile ? true : false}
      responsive={responsive}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-danger">
          <GiPschentDoubleCrown />
        </Card.Body>
        <strong className="fs-4">PSC</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-primary">
          <FaSchool />
        </Card.Body>
        <strong className="fs-4">JSC</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-success">
          <IoIosSchool />
        </Card.Body>
        <strong className="fs-4">SSC</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-warning">
          <GiSchoolBag />
        </Card.Body>
        <strong className="fs-4">HSC</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-info">
          <FaUniversalAccess />
        </Card.Body>
        <strong className="fs-4">O Level</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-danger">
          <GiStumpRegrowth />
        </Card.Body>
        <strong className="fs-4">A Level</strong>
      </Card>
      <Card className="text-center subjectBorder me-3 pb-3 mb-3 mb-md-0 rounded expertAnimation">
        <Card.Body className="display-2 text-muted">
          <FaRunning />
        </Card.Body>
        <strong className="fs-4">Class-9</strong>
      </Card>
    </Carousel>
  );
};

export default LandingPageClass;
